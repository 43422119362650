import type {FC} from 'react';
import React from 'react';
import {useQuery} from '@apollo/client';
import {useLocation} from 'react-router-dom';

import logger from '@core/logger';
import isPaymentUrl from '@core/utils/url/isPayUrl';
import isPaySuccessUrl from '@core/utils/url/isPaySuccessUrl';
import BabciaScopedProvider from '@core/tracking/babcia/containers/BabciaScopedProvider';

import useIsSepaGuaranteesAllowed from '../../../common/utils/useIsSepaGuaranteesAllowed';
import usePaymentParams from '../../../common/utils/usePaymentParams';
import useIsComplianceFriendlyAllowed from '../../../common/utils/useIsComplianceFriendlyAllowed';
import usePaymentAdditionalPackage from '../../additionalPackage/utils/usePaymentAdditionalPackage';
import PAYMENT_FOOTER_QUERY from '../graphql/queries/paymentFooter.gql';
import type {
  PaymentFooterQuery,
  PaymentFooterQueryVariables,
} from '../graphql/queries/paymentFooter';
import type {PaymentFooterCommonProps} from '../types/paymentFooterProps';

const PaymentFooterCommon: FC<PaymentFooterCommonProps> = ({
  layout: PaymentFooterLayout,
  placeholder: PaymentFooterPlaceholder,
  errorBoundary: ErrorBoundary,
  isFirstStep = false,
  packageDescriptionOnTop: packageDescriptionOnTopFromProps = true,
  withAdditionalPackage = true,
  withActivationFee = true,
  withBillingPolicy = true,
  withCredentialsPolicy = true,
  withGuarantees = true,
  withContacts = false,
  subscriptionInfo,
  withCopyright = true,
  withDescriptor = true,
  withDnsmpi = true,
  withPackageDescription = true,
  withSecurityLogos = true,
  withHotLine = true,
  withUserPublicId = true,
  withDetails = true,
  withCurrencyConvertStatement = true,
  withKonbiniInfo = false,
  ...props
}) => {
  const {pathname} = useLocation();
  const {action, via, prevVia, source} = usePaymentParams();

  const {data, error, loading} = useQuery<
    PaymentFooterQuery,
    PaymentFooterQueryVariables
  >(PAYMENT_FOOTER_QUERY, {
    variables: {
      action,
      via,
      prevVia,
      source,
    },
  });

  const {isComplianceFriendly, loading: isComplianceFriendlyLoading} =
    useIsComplianceFriendlyAllowed();
  const guaranteesAllowed = useIsSepaGuaranteesAllowed();

  const {
    showAdditionalPackage,
    isAdditionalPackageFirst,
    loading: paymentAdditionalPackageLoading,
  } = usePaymentAdditionalPackage();

  if (
    loading ||
    isComplianceFriendlyLoading ||
    paymentAdditionalPackageLoading
  ) {
    return <PaymentFooterPlaceholder />;
  }

  if (error) {
    return <ErrorBoundary error={error} />;
  }

  if (!data?.payment?.paymentFooter?.blockDataMap) {
    logger.sendError('[PaymentFooter] There is no data in footer');
    return null;
  }

  const packageDescriptionOnTop =
    packageDescriptionOnTopFromProps &&
    isComplianceFriendly &&
    isPaymentUrl(pathname);

  const canShowInfo =
    !guaranteesAllowed || (guaranteesAllowed && isPaySuccessUrl(pathname));

  const {
    payment: {
      paymentFooter: {
        blockDataMap: {
          copyright,
          hotlineCreditCard,
          footerLicense,
          currencyConvertStatement,
        },
        displaySettingData: {userPublicId} = {},
      },
    },
  } = data;

  return (
    <BabciaScopedProvider context="footer">
      <PaymentFooterLayout
        copyright={copyright}
        currencyConvertStatement={currencyConvertStatement}
        hotlineCreditCard={hotlineCreditCard}
        userPublicId={userPublicId}
        footerLicense={footerLicense}
        withGuarantees={withGuarantees && guaranteesAllowed}
        isAdditionalPackageFirst={isAdditionalPackageFirst}
        isFirstStep={isFirstStep}
        packageDescriptionOnTop={packageDescriptionOnTop}
        subscriptionInfo={subscriptionInfo}
        withBillingPolicy={withBillingPolicy}
        withCredentialsPolicy={withCredentialsPolicy}
        withCopyright={withCopyright}
        withDescriptor={withDescriptor}
        withContacts={withContacts}
        withDetails={withDetails}
        withDnsmpi={withDnsmpi}
        withPackageDescription={withPackageDescription}
        withSecurityLogos={withSecurityLogos}
        withAdditionalPackage={withAdditionalPackage && showAdditionalPackage}
        withActivationFee={withActivationFee}
        withUserPublicId={withUserPublicId && canShowInfo}
        withHotLine={withHotLine && canShowInfo}
        withCurrencyConvertStatement={withCurrencyConvertStatement}
        withKonbiniInfo={withKonbiniInfo}
        {...props}
      />
    </BabciaScopedProvider>
  );
};

export default PaymentFooterCommon;
